<!-- 立方体 3d 柱状图 -->
<template>
  <div class='chart-view' ref="barChartCube"></div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: '',
  props: {
    data: {
      type: Object,
    },
    colorArr: {
      type: Array,
      default: () => {
        return [
          'rgba(255, 244, 144, 1)',
          'rgba(222, 106, 73, 1)',
          'rgba(196, 231, 254, 1)',
          'rgba(255, 153, 0, 1)',
        ]
      },
    },
  },
  components: {},
  data() {
    return {}
  },
  computed: {},
  watch: {
    data: {
      handler(val) {
        if (Object.keys(val).length != 0) {
          this.init(val.dataX, val.dataYOne, val.dataYTwo)
        }
      },
      deep: true,
    },
  },
  methods: {
    //右侧的柱状图
    init(dataX, dataYOne, dataYTwo) {
      console.log('dataX', dataX)
      let _this = this
      var chartDom = this.$refs['barChartCube']
      var myChart = echarts.init(chartDom)
      // mock 数据
      const dataArr = {
        xdata: dataX,
        disinfeced: [20, 30, 30, 40],
        placeData: [100, 100, 100, 100],
      }

      const grid = { top: '14%', left: '-2%', right: '2%', bottom: '15%' }
      // xAxis
      const xAxis = {
        axisTick: { show: false },
        axisLine: { show: false },
        axisLabel: {
          textStyle: { fontSize: this.transformFontSize(20), color: '#fff' },
          margin: 20,
          interval: 0,
        },
        data: dataX,
      }
      // yAxis
      const yAxis = [
        {
          show: false,
        },
        {
          // 双y轴，主要用于折线图，所以不需要显示坐标
          show: false,
        },
      ]
      // series
      const series = [
        {
          z: 3,
          name: '上部1',
          type: 'pictorialBar',
          symbolPosition: 'end',
          data: dataArr.placeData,
          symbol: 'diamond',
          symbolOffset: [0, '-50%'],
          symbolSize: [40, 20],
          itemStyle: {
            color: function (params) {
              return _this.colorArr[params.dataIndex]
            },
          },
          label: {
            show: true,
            position: 'top',
            color: '#fff',
            fontSize: this.transformFontSize(24),
            formatter: (params) => {
              return dataYOne[params.dataIndex] + '%'
            },
          },
        },
        {
          z: 1,
          type: 'bar',
          name: '场所数',
          barGap: '-100%',
          barWidth: 40,
          data: dataArr.placeData,
          itemStyle: {
            color: function (params) {
              return _this.colorBgFn(params.name)
            },
          },
        },
        {
          z: 2,
          name: '底部',
          type: 'pictorialBar',
          data: [1, 1, 1, 1, 1, 1, 1, 1],
          symbol: 'diamond',
          symbolOffset: [0, '50%'],
          symbolSize: [40, 20],
          itemStyle: {
            color: function (params) {
              return _this.colorArr[params.dataIndex]
            },
          },
        },
        {
          z: 3,
          name: '上部1',
          type: 'pictorialBar',
          symbolPosition: 'end',
          data: dataYOne,
          symbol: 'diamond',
          symbolOffset: [0, '-50%'],
          symbolSize: [40, 20],
          itemStyle: {
            color: function (params) {
              return _this.colorArr[params.dataIndex]
            },
          },
        },
        {
          z: 1,
          stack: '总量',
          type: 'bar',
          name: '已消杀',
          barWidth: 40,
          // barGap: '-100%',
          data: dataYOne,
          itemStyle: {
            color: function (params) {
              return _this.colorArr[params.dataIndex]
            },
          },
        },
      ]
      let option = {
        xAxis,
        yAxis,
        series,
        grid,
      }
      option && myChart.setOption(option)
    },
    colorBgFn(index) {
      console.log(index)
      var colorList = {
        天猫国际: {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(255, 230, 135,.2)' },
            { offset: 0.5, color: 'rgba(255, 230, 135,.2)' },
            { offset: 0.5, color: 'rgba(255, 230, 135,.7)' },
            { offset: 1, color: 'rgba(255, 230, 135,.2)' },
          ],
        },
        京东国际: {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(255, 153, 0,.2)' },
            { offset: 0.5, color: 'rgba(255, 153, 0,.2)' },
            { offset: 0.5, color: 'rgba(255, 153, 0,.7)' },
            { offset: 1, color: 'rgba(255, 153, 0,.2)' },
          ],
        },
        唯品国际: {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(196, 231, 254,.2)' },
            { offset: 0.5, color: 'rgba(196, 231, 254,.2)' },
            { offset: 0.5, color: 'rgba(196, 231, 254,.7)' },
            { offset: 1, color: 'rgba(196, 231, 254,.2)' },
          ],
        },
        拼多多海淘: {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(222, 106, 73,.2)' },
            { offset: 0.5, color: 'rgba(222, 106, 73,.2)' },
            { offset: 0.5, color: 'rgba(222, 106, 73,.7)' },
            { offset: 1, color: 'rgba(222, 106, 73,.2)' },
          ],
        },
        'Tmall Global': {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(255, 230, 135,.2)' },
            { offset: 0.5, color: 'rgba(255, 230, 135,.2)' },
            { offset: 0.5, color: 'rgba(255, 230, 135,.7)' },
            { offset: 1, color: 'rgba(255, 230, 135,.2)' },
          ],
        },
        'JD Worldwide': {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(255, 153, 0,.2)' },
            { offset: 0.5, color: 'rgba(255, 153, 0,.2)' },
            { offset: 0.5, color: 'rgba(255, 153, 0,.7)' },
            { offset: 1, color: 'rgba(255, 153, 0,.2)' },
          ],
        },
        'Vip International': {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(196, 231, 254,.2)' },
            { offset: 0.5, color: 'rgba(196, 231, 254,.2)' },
            { offset: 0.5, color: 'rgba(196, 231, 254,.7)' },
            { offset: 1, color: 'rgba(196, 231, 254,.2)' },
          ],
        },
        'PDD International': {
          type: 'linear',
          x: 0,
          x2: 1,
          y: 0,
          y2: 0,
          colorStops: [
            { offset: 0, color: 'rgba(222, 106, 73,.2)' },
            { offset: 0.5, color: 'rgba(222, 106, 73,.2)' },
            { offset: 0.5, color: 'rgba(222, 106, 73,.7)' },
            { offset: 1, color: 'rgba(222, 106, 73,.2)' },
          ],
        },
        // 添加更多的渐变色...
      }
      return colorList[index]
    },
    transformFontSize(fontsize) {
      const width = window.screen.width
      const ration = width / 1920
      return parseInt((fontsize * ration).toString())
    },
  },
  created() {},
  mounted() {
    if (Object.keys(this.data).length != 0) {
      this.init(this.data.dataX, this.data.dataYOne, this.data.dataYTwo)
    }
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang='scss' scoped>
.chart-view {
  width: 100%;
  height: 100%;
}
</style>
<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <TitleAndTime :time="dataTime">
      <img src="@/assets/speaial_activity_img/image/threetitle.png" class="title" alt="">
    </TitleAndTime>
    <div class="main flex jc-sb">
      <div class="left">
        <SubTitleAndIcon>
          <p>综合电商销售总额及各平台销售额占比</p>
        </SubTitleAndIcon>
        <div class="number">
          {{ detail.b2cSales.sales }}亿元
        </div>
        <div style="width:100%;height: 60%">
          <PieChart3D :data="pieData" :colorArr="color"></PieChart3D>
        </div>
      </div>
      <div class="right">
        <SubTitleAndIcon>
          <p>综合电商平台海外购业务销售额</p>
        </SubTitleAndIcon>
        <div class="number">
          {{ detail.overseasGmv.sales }}亿元
        </div>
        <div style="width:100%;height: 70%">
          <BarChart3DCube :data="barData" :colorArr="color2"></BarChart3DCube>
        </div>
      </div>
    </div>
    <Annotation pageNum="03"></Annotation>
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import SubTitleAndIcon from '@/components/Big/SubTitleAndIcon'
import TitleAndTime from '@/components/Big/TitleAndTime.vue'
import BarChart3DCube from '@/components/Big/BarChart3DCube'
import PieChart3D from '@/components/Big/PieChart3D.vue'
import Annotation from '@/components/Big/Annotation'
import toolMixin from '../../../../utils/tool'
export default {
  mixins: [toolMixin],
  props: {
    data: {
      type: Object,
      required: true,
      default: () => {
        return {
          b2cSales: { sales: 0 },
          overseasSales: [],
          mainPlatformSales: [],
          overseasGmv: {
            time: '',
            sales: '',
          },
        }
      },
    },
  },
  components: {
    Headers,
    SubTitleAndIcon,
    TitleAndTime,
    BarChart3DCube,
    Annotation,
    PieChart3D,
  },
  watch: {
    data(newValue, oldValue) {
      this.init(newValue)
    },
  },
  data() {
    return {
      pieData: {},
      barData: {},
      dataTime: '',
      detail: {
        b2cSales: { sales: 0 },
        overseasSales: [],
        mainPlatformSales: [],
        overseasGmv: {
          time: '',
          sales: '',
        },
      },
      color: [],
      color2: [],
    }
  },
  mounted() {
    this.init(this.data)
  },
  methods: {
    init(newValue) {
      if (JSON.stringify(newValue) == '{}') {
        return
      }
      // console.log('楼层 3 zn', newValue)
      this.detail = newValue
      const colorConfig = {
        京东: '#FF9900',
        天猫: '#FFE687',
        拼多多: '#DE6A49',
        其他: '#C4E7FE',
        唯品: '#C4E7FE',
      }
      // 设置饼图数据
      let bingData = []
      if (newValue.mainPlatformSales.length !== 0) {
        this.dataTime = newValue.mainPlatformSales[0].time + '数据时段'
        newValue.mainPlatformSales.forEach((item) => {
          if (item.platform === '苏宁国际') return
          bingData.push({
            value: this.newToFixed(item.proportion, 2, 10000),
            name: item.platform,
          })
          for (const key in colorConfig) {
            if (item.platform.indexOf(key) != -1) {
              this.color.push(colorConfig[key])
            }
          }
        })
      }

      // 设置柱状图数据
      let dataX = []
      let dataYOne = []
      let dataYTwo = []
      if (newValue.overseasSales && newValue.overseasSales.length !== 0) {
        newValue.overseasSales.forEach((item) => {
          dataX.push(item.platform)
          let value = (
            Number(item.salesShareOrExportRatio.slice(0, -1)) * 100
          ).toFixed(0)
          for (const key in colorConfig) {
            if (item.platform.indexOf(key) != -1) {
              this.color2.push(colorConfig[key])
            }
          }
          dataYOne.push(value)
          dataYTwo.push(100)
        })
      }
      this.pieData = bingData
      this.barData = { dataX, dataYOne, dataYTwo }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>
